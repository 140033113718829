.login_title > h1 {
  margin-bottom: 0;
  font-size: var(--font-size-lg);
  font-weight: bold;
  color: var(--color-text);
  margin-top: 15px;
}

.login_title p {
  font-size: var(--font-size-sm);
  color: var(--color-text);
  margin-bottom: 15px;
}

.forget_link {
  margin-top: 10px;
  font-size: var(--font-size-sm);
  color: var(--color-primary);
  cursor: pointer;
}

.outer__container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--color-white-smoke);
  flex-direction: column;
}

.outer_box {
  display: grid;
  width: 700px;
  height: 450px;
  background-color: var(--color-white);
  border-radius: 10px;
  box-shadow: var(--box-shadow-base);
  grid-template-columns: 1fr 1fr;
}

.outer_side_banner {
  border-radius: 10px 0 0 10px;
  height: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(7, 95, 169, 0.5),
      rgba(0, 0, 0, 0.35)
    ),
    url("../images/001.png");
  background-size: cover;
  background-position: center center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.outer_side_content {
  padding: 20px;
}

.outer_logo {
  display: flex;
  align-items: center;
  justify-content: left;
}

.outer_logo > img {
  /* width: 100px; */
}

.footer {
  position: absolute;
  bottom: 10px;
  color: #455560;
  font-size: 12px;
}

.footer_2 {
  margin-top: 5px;
  color: #455560;
  font-size: 12px;
}

.signup-link {
  text-align: center;
  margin-top: 3px;
  font-size: var(--font-size-sm);
  color: var(--color-text);
  font-weight: 600;
}

.fw-link {
  text-align: right;
  margin-bottom: 10px;
  font-size: var(--font-size-sm);
  color: var(--color-text);
  font-weight: 500;
}

.rg_box{
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}